import { createSlice } from '@reduxjs/toolkit';
import {
  getProductSlider,
  getArticleData,
  getArticleHighlight,
  getQuizData,
  getRelatedPost,
  getRelatedProduct,
  getMustCheck,
  getRelatedQuiz,
  postActionArticle,
  getCommentList,
  postQuizAnswer,
  getPreviewArticleData,
  getPreviewQuizData,
  getRelatedArticleData,
} from './articleThunk';
import { removeDuplicateArray } from '@web-utils';

const initialState = {
  status: {
    getProductSliderFetch: false,
    getProductSliderError: null,
    getArticleDataFetch: false,
    getArticleDataError: null,
    getArticleHighlightFetch: false,
    getArticleHighlightError: null,
    getQuizFetch: false,
    getQuizError: null,
    getRelatedPostFetch: false,
    getRelatedPostError: null,
    postActionArticleFetch: false,
    postActionArticleError: null,
    getCommentListFetch: false,
    getCommentListError: null,
    postQuizAnswerFetch: false,
    postQuizAnswerError: null,
  },
  articleData: null,
  productData: [],
  articlePage: 0,
  articleHighlight: null,
  quizData: null,
  relatedPost: null,
  mustCheckData: null,
  relatedQuizData: null,
  commentListData: null,
  factData: null,
  quizResultData: null,
  relatedArticleData: null,
};

const { reducer, actions } = createSlice({
  name: 'article',
  initialState,
  reducers: {
    setActivePage(state, action) {
      state.articlePage = action.payload;
    },
    resetArticleData(state) {
      state.articleData = null;
      state.status.getArticleDataError = null;
      state.mustCheckData = null;
      state.relatedArticleData = null;
    },
    resetQuizData(state, type) {
      if (type === 'result') {
        state.quizResultData = null;
      } else {
        state.quizData = null;
        state.relatedPost = null;
        state.productData = [];
        state.relatedQuizData = null;
        state.quizResultData = null;
      }
    },
    resetCommentListData(state) {
      state.commentListData = null;
    },
    setFactData(state, action) {
      state.factData = action.payload;
    },
    resetFactData(state) {
      state.factData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductSlider.pending, (state) => {
        state.status.getProductSliderError = null;
        state.status.getProductSliderFetch = true;
      })
      .addCase(getProductSlider.rejected, (state, action) => {
        state.status.getProductSliderFetch = false;
        state.status.getProductSliderError = action?.error?.message;
      })
      .addCase(getProductSlider.fulfilled, (state, { payload }) => {
        state.status.getProductSliderFetch = false;
        state.productData = removeDuplicateArray(
          state.productData.concat(payload.data),
          '_id',
        );
      })
      .addCase(getArticleData.pending, (state) => {
        state.status.getArticleDataFetch = true;
        state.status.getArticleDataError = null;
      })
      .addCase(getArticleData.rejected, (state, action) => {
        state.status.getArticleDataFetch = false;
        state.status.getArticleDataError = action?.error?.message;
      })
      .addCase(getArticleData.fulfilled, (state, { payload }) => {
        state.status.getArticleDataFetch = false;
        state.articleData = payload.data;
      })
      .addCase(getArticleHighlight.pending, (state) => {
        state.status.getArticleHighlightFetch = true;
        state.status.getArticleHighlightError = null;
      })
      .addCase(getArticleHighlight.rejected, (state, action) => {
        state.status.getArticleHighlightFetch = false;
        state.status.getArticleHighlightError = action?.error?.message;
      })
      .addCase(getArticleHighlight.fulfilled, (state, { payload }) => {
        state.status.getArticleHighlightFetch = false;
        state.articleHighlight = payload.data;
      })
      .addCase(getQuizData.pending, (state) => {
        state.status.getQuizFetch = true;
        state.status.getQuizError = null;
      })
      .addCase(getQuizData.rejected, (state, action) => {
        state.status.getQuizFetch = false;
        state.status.getQuizError = action?.error?.message;
      })
      .addCase(getQuizData.fulfilled, (state, { payload }) => {
        state.status.getQuizFetch = false;
        state.quizData = payload.data;
      })
      .addCase(getRelatedPost.pending, (state) => {
        state.status.getRelatedPostFetch = true;
        state.status.getRelatedPostError = null;
      })
      .addCase(getRelatedPost.rejected, (state, action) => {
        state.status.getRelatedPostFetch = false;
        state.status.getRelatedPostError = action?.error?.message;
      })
      .addCase(getRelatedPost.fulfilled, (state, { payload }) => {
        state.status.getRelatedPostFetch = false;
        state.relatedPost = payload.data;
      })
      .addCase(getRelatedProduct.pending, (state) => {
        state.status.getProductSliderError = null;
        state.status.getProductSliderFetch = true;
      })
      .addCase(getRelatedProduct.rejected, (state, action) => {
        state.status.getProductSliderFetch = false;
        state.status.getProductSliderError = action?.error?.message;
      })
      .addCase(getRelatedProduct.fulfilled, (state, { payload }) => {
        state.status.getProductSliderFetch = false;
        state.productData = removeDuplicateArray(
          state.productData.concat(payload.data),
          '_id',
        );
      })
      .addCase(getMustCheck.fulfilled, (state, { payload }) => {
        state.mustCheckData = payload.data;
      })
      .addCase(getRelatedQuiz.fulfilled, (state, { payload }) => {
        state.relatedQuizData = payload.data;
      })
      .addCase(postActionArticle.pending, (state) => {
        state.status.postActionArticleError = null;
        state.status.postActionArticleFetch = true;
      })
      .addCase(postActionArticle.rejected, (state, action) => {
        state.status.postActionArticleFetch = false;
        state.status.postActionArticleError = action?.error?.message;
      })
      .addCase(postActionArticle.fulfilled, (state) => {
        state.status.postActionArticleFetch = false;
      })
      .addCase(getCommentList.pending, (state) => {
        state.status.getCommentListError = null;
        state.status.getCommentListFetch = true;
      })
      .addCase(getCommentList.rejected, (state, action) => {
        state.status.getCommentListFetch = false;
        state.status.getCommentListError = action?.error?.message;
      })
      .addCase(getCommentList.fulfilled, (state, { payload }) => {
        state.status.getCommentListFetch = false;
        state.commentListData = payload.data;
      })
      .addCase(postQuizAnswer.pending, (state) => {
        state.status.postQuizAnswerError = null;
        state.status.postQuizAnswerFetch = true;
      })
      .addCase(postQuizAnswer.rejected, (state, action) => {
        state.status.postQuizAnswerFetch = false;
        state.status.postQuizAnswerError = action?.error?.message;
      })
      .addCase(postQuizAnswer.fulfilled, (state, { payload }) => {
        state.status.postQuizAnswerFetch = false;
        state.quizResultData = payload.data;
      })
      .addCase(getPreviewArticleData.pending, (state) => {
        state.status.getArticleDataFetch = true;
        state.status.getArticleDataError = null;
      })
      .addCase(getPreviewArticleData.rejected, (state, action) => {
        state.status.getArticleDataFetch = false;
        state.status.getArticleDataError = action?.error?.message;
      })
      .addCase(getPreviewArticleData.fulfilled, (state, { payload }) => {
        state.status.getArticleDataFetch = false;
        state.articleData = payload.data;
      })
      .addCase(getPreviewQuizData.pending, (state) => {
        state.status.getQuizFetch = true;
        state.status.getQuizError = null;
      })
      .addCase(getPreviewQuizData.rejected, (state, action) => {
        state.status.getQuizFetch = false;
        state.status.getQuizError = action?.error?.message;
      })
      .addCase(getPreviewQuizData.fulfilled, (state, { payload }) => {
        state.status.getQuizFetch = false;
        state.quizData = payload.data;
      })
      .addCase(getRelatedArticleData.fulfilled, (state, { payload }) => {
        state.relatedArticleData = payload.data;
      });
  },
});

export const {
  setActivePage,
  resetArticleData,
  resetQuizData,
  resetCommentListData,
  setFactData,
  resetFactData,
} = actions;

export const selectArticleFetchStatus = (type) => {
  return (state) => state.article.status[type];
};
export const selectArticleState = (type) => {
  return (state) => state.article[type];
};

export default reducer;
