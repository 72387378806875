/* eslint-disable react-native/no-inline-styles */

import React, { useState, useCallback, memo, useEffect, useRef } from 'react';
import RenderHtml, { defaultSystemFonts } from 'react-native-render-html';
import PropTypes from 'prop-types';
import { View, ActivityIndicator, Text } from 'react-native';
import { hs, Colors } from '@web-styles';

import {
  productSliderRGX,
  stbRegex,
  embedRegex,
  owlContainerRegex,
  splitter,
  iframeEncodedRegex,
  embedTagRegex,
} from './Regex.constant';
import { screenWidth, isWeb, CreateResponsiveStyle } from '~global-screens';
import { General, Section } from '@web-styles';

import productRender from './productRender';
import imageSlideRender from './imageSlideRender';
import youtubeRender from './youtubeRender';
import tiktokRender from './tiktokRender';
import listRender from './listRender';
import filterParagraphRender from './filterParagraph';
import carouselRender from './carouselRender';
import instagramRender from './instagramRender';
import twitterRender from './twitterRender';
import spotifyRender from './spotifyRender';
import factRender from './factRender';
import TableOfContents from '../tableOfContents/TableOfContents';

const IGNORED_TAGS = ['br'];
const systemFonts = ['Work Sans', ...defaultSystemFonts];

const renderers = {
  ...productRender.renderers,
  ...imageSlideRender.renderers,
  ...youtubeRender.renderers,
  ...tiktokRender.renderers,
  ...listRender.renderers,
  ...carouselRender.renderers,
  ...instagramRender.renderers,
  ...twitterRender.renderers,
  ...spotifyRender.renderers,
  ...factRender.renderers,
};

const customHTMLElementModels = {
  ...productRender.customHTMLElementModels,
  ...imageSlideRender.customHTMLElementModels,
  ...youtubeRender.customHTMLElementModels,
  ...tiktokRender.customHTMLElementModels,
  ...listRender.customHTMLElementModels,
  ...carouselRender.customHTMLElementModels,
  ...instagramRender.customHTMLElementModels,
  ...twitterRender.customHTMLElementModels,
  ...spotifyRender.customHTMLElementModels,
  ...factRender.customHTMLElementModels,
};

const MemoizedRenderHtml = memo(RenderHtml);

const InfiniteScroll = ({
  htmlContent,
  maxWidth,
  innerRef,
  darkmode,
  revisionId,
  scrollY,
}) => {
  const styles = componentStyles();
  const [parsedContent, setParsedContent] = useState(
    htmlContent ?? '<html></html>',
  );
  const [tempContent, setTempContent] = useState([]);
  const [splitContent, setSplitContent] = useState(null);
  const [page, setPage] = useState(1);
  const [loadedPages, setLoadedPages] = useState([1]);
  const prevScrollY = useRef(scrollY);
  const activityIndicatorsRef = useRef([]);
  const headingsRef = useRef([]);
  const pageRefs = useRef([]);
  const pageHeights = useRef([]);
  const pageHistory = useRef([]);
  const [count, setCount] = useState([]);

  useEffect(() => {
    const storedCount = window.sessionStorage.getItem('count');
    if (storedCount) {
      try {
        const parsedCount = JSON.parse(storedCount);
        if (Array.isArray(parsedCount)) {
          setCount(parsedCount);
        }
      } catch (e) {
        console.error('Error parsing count from sessionStorage', e);
        setCount([]);
      }
    }
    const queryPage = parseInt(
      new URLSearchParams(window.location.search).get('page'),
    );
    if (queryPage && queryPage > 1) {
      handlePageChange(1);
    }
  }, []);

  useEffect(() => {
    if (count.length > 0) {
      window.sessionStorage.setItem('count', JSON.stringify(count));
    }
  }, [count]);

  const handlePageChange = useCallback(
    (newPage) => {
      if (!pageHistory.current.includes(newPage)) {
        pageHistory.current.push(newPage);
      }
      const newUrl = `${window.location.pathname}?${
        revisionId ? `revision=${revisionId}&` : ''
      }page=${newPage}`;
      history.replaceState(null, null, newUrl);
      setPage(newPage);
      setLoadedPages((prevLoadedPages) => {
        const updatedPages = [...prevLoadedPages];
        if (!updatedPages.includes(newPage)) {
          updatedPages.push(newPage);
        }
        return updatedPages;
      });
      setCount((prevCount) => {
        if (!prevCount.includes(newPage)) {
          const updatedCount = [...prevCount, newPage];
          window.sessionStorage.setItem('count', JSON.stringify(updatedCount));
          return updatedCount;
        }
        return prevCount;
      });
      if (innerRef?.current) {
        innerRef.current.scrollTo({ y: 0, animated: true });
      }
    },
    [innerRef, revisionId],
  );

  const handleShowAll = useCallback(() => {
    if (window.location.pathname.includes('preview') || revisionId) {
      history.pushState(
        null,
        null,
        `${window.location.pathname}?revision=${revisionId}&page=all`,
      );
    } else {
      history.pushState(null, null, `${window.location.pathname}?page=all`);
    }
  }, []);

  useEffect(() => {
    pageRefs.current.forEach((ref, index) => {
      if (ref) {
        ref.measure((x, y, width, height, pageX, pageY) => {
          pageHeights.current[index] = height;
        });
      }
    });
  }, [tempContent]);

  useEffect(() => {
    const currentScrollY = scrollY;
    const scrollDirection =
      currentScrollY > prevScrollY.current ? 'down' : 'up';

    activityIndicatorsRef.current.forEach((indicator, index) => {
      if (indicator) {
        indicator.measure((x, y, width, height, pageX, pageY) => {
          const totalPageHeight = pageHeights.current
            .slice(0, page)
            .reduce((acc, val) => acc + val, 0);
          const totalPageHeightExceptLast = pageHeights.current
            .slice(0, page - 1)
            .reduce((acc, val) => acc + val, 0);
          if (scrollDirection === 'down') {
            if (currentScrollY + window.innerHeight > totalPageHeight) {
              if (page < tempContent.length) {
                setTimeout(() => {
                  handlePageChange(page + 1);
                }, 800);
              }
            }
          } else if (
            scrollDirection === 'up' &&
            currentScrollY < totalPageHeightExceptLast
          ) {
            if (page > 1) {
              setTimeout(() => {
                handlePageChange(page - 1);
              }, 800);
            }
          }
        });
      }
    });

    prevScrollY.current = currentScrollY;
  }, [scrollY, page, handlePageChange]);

  useEffect(() => {
    if (tempContent.length > 0) {
      const parser = new DOMParser();
      tempContent.forEach((pageContent, index) => {
        const doc = parser.parseFromString(pageContent, 'text/html');
        const firstH3 = doc.querySelector('h3');
        const firstH4 = doc.querySelector('h4');
        headingsRef.current[index + 1] = firstH3
          ? firstH3.textContent.trim()
          : firstH4
          ? firstH4.textContent.trim()
          : 'No heading';
      });
    }
  }, [tempContent]);

  useEffect(() => {
    let newParsedContent = htmlContent ?? '<html></html>';

    const filterParagraph = newParsedContent;
    if (filterParagraph) {
      const matchParagraph = filterParagraph.split('\n');
      const res = filterParagraphRender.parseHtmlData(
        matchParagraph,
        newParsedContent,
      );
      const fixTag = res.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
      newParsedContent = fixTag;
    }
    const prodSlider = newParsedContent.match(productSliderRGX);
    if (prodSlider) {
      const res = productRender.parseHtmlData(prodSlider, newParsedContent);
      newParsedContent = res;
    }
    const stbArrays = newParsedContent.match(stbRegex);
    if (stbArrays) {
      const res = imageSlideRender.parseHtmlData(stbArrays, newParsedContent);
      newParsedContent = res;
    }
    const embededData = newParsedContent.match(embedRegex);
    if (embededData) {
      const youtubeData = [];
      const tiktokData = [];
      const instagramData = [];
      const twitterData = [];
      for (let i = 0; i < embededData.length; i++) {
        if (!embededData[i].includes('href')) {
          if (embededData[i].indexOf('youtu') > 0) {
            youtubeData.push(embededData[i]);
          }
          if (embededData[i].indexOf('tiktok') > 0) {
            tiktokData.push(embededData[i]);
          }
          if (embededData[i].indexOf('instagram') > 0) {
            instagramData.push(embededData[i]);
          }
          if (
            embededData[i].indexOf('twitter') > 0 ||
            embededData[i].indexOf('x.com') > 0
          ) {
            twitterData.push(embededData[i]);
          }
        }
      }
      if (youtubeData) {
        const res = youtubeRender.parseHtmlData(youtubeData, newParsedContent);
        newParsedContent = res;
      }
      if (tiktokData) {
        const res = tiktokRender.parseHtmlData(tiktokData, newParsedContent);
        newParsedContent = res;
      }
      if (instagramData) {
        const res = instagramRender.parseHtmlData(
          instagramData,
          newParsedContent,
        );
        newParsedContent = res;
      }
      if (twitterData) {
        const res = twitterRender.parseHtmlData(twitterData, newParsedContent);
        newParsedContent = res;
      }
    }
    const embedTag = newParsedContent.match(embedTagRegex);
    if (embedTag) {
      newParsedContent = newParsedContent.replace(embedTagRegex, '');
    }
    const iframeSpotifyData = newParsedContent.match(iframeEncodedRegex);
    if (iframeSpotifyData) {
      const res = spotifyRender.parseHtmlData(
        iframeSpotifyData,
        newParsedContent,
      );
      newParsedContent = res;
    }
    const carouselData = newParsedContent.match(owlContainerRegex);
    if (carouselData) {
      const res = carouselRender.parseHtmlData(carouselData, newParsedContent);
      newParsedContent = res;
    }
    const caption = newParsedContent.match(
      /\[caption(.*?)\](.*?)\[\/caption\]/g,
    );
    if (caption) {
      for (let x = 0; x < caption.length; x++) {
        let result = caption[x].replace(/\[(.*?)caption(.*?)\]/g, '');
        result = result.replace(`</a> <a`, `</a><br/> <a`);
        result = '<p style="text-align: center">' + result + '</p>';
        newParsedContent = newParsedContent.replace(caption[x], result);
      }
    }
    const captionimg = newParsedContent.match(
      /\[caption-img(.*?)\](.*?)\[\/caption-img\]/g,
    );
    if (captionimg) {
      for (let x = 0; x < captionimg.length; x++) {
        const result = captionimg[x].replace(/\[(.*?)caption-img(.*?)\]/g, '');
        const resultSrc = captionimg[x].match(/src="([^"]*)"/i);
        const resultAlt = captionimg[x].match(/alt="([^"]*)"/i);
        let outputCaption;
        outputCaption = '<div>';
        if (resultSrc && resultSrc.length && resultAlt && resultAlt.length) {
          outputCaption += `<img src="${resultSrc[1]}" alt="${resultAlt[1]}"/>`;
        }
        if (result) {
          outputCaption += `<p style="text-align: center">${result}</p>`;
        }
        outputCaption += '</div>';
        newParsedContent = newParsedContent.replace(
          captionimg[x],
          outputCaption,
        );
      }
    }

    const newSplitContent = newParsedContent.match(splitter);
    if (newSplitContent) {
      const res = newParsedContent
        .replace(splitter, '---split page---')
        .split('---split page---');
      const resNoSplit = newParsedContent.replace(splitter, '');
      newParsedContent = resNoSplit;
      setTempContent(res);
    }

    setSplitContent(newSplitContent);
    setParsedContent(newParsedContent);
  }, []);

  const HTML_CONFIG = {
    p: {
      fontFamily: 'Work Sans',
      fontSize: isWeb ? 18 : 13,
      lineHeight: isWeb ? '26px' : '22px',
      letterSpacing: isWeb ? 0.4 : 0,
      marginTop: 0,
      color: darkmode ? Colors.WHITE : Colors.BLACK,
      fontWeight: 400,
    },
    strong: {
      fontFamily: 'Work Sans',
      fontSize: isWeb ? 18 : 13,
      lineHeight: isWeb ? '26px' : '22px',
      letterSpacing: isWeb ? 0.4 : 0,
      marginTop: 0,
      fontWeight: 600,
    },
    h1: {
      fontFamily: 'Work Sans',
      fontSize: 30,
      fontWeight: 'normal',
      color: darkmode ? Colors.WHITE : Colors.BLACK,
    },
    h2: {
      fontFamily: 'Work Sans',
      fontSize: 26,
      fontWeight: 'normal',
      color: darkmode ? Colors.WHITE : Colors.BLACK,
    },
    h3: {
      fontFamily: 'Work Sans',
      fontSize: isWeb ? 24 : 20,
      fontWeight: 'bold',
      color: darkmode ? Colors.WHITE : Colors.BLACK,
    },
    h4: {
      fontFamily: 'Work Sans',
      fontSize: isWeb ? 20 : 18,
      fontWeight: 'normal',
      color: darkmode ? Colors.WHITE : Colors.BLACK,
    },
    h5: {
      fontFamily: 'Work Sans',
      fontSize: 18,
      fontWeight: 'normal',
      color: darkmode ? Colors.WHITE : Colors.BLACK,
    },
    h6: {
      fontFamily: 'Work Sans',
      fontSize: 16,
      fontWeight: 'normal',
      color: darkmode ? Colors.WHITE : Colors.BLACK,
    },
    a: {
      color: Colors.AMARANTH_PURPLE,
      textDecorationLine: 'none',
    },
    li: {
      fontFamily: 'Work Sans',
      fontSize: isWeb ? 18 : 13,
      lineHeight: isWeb ? '26px' : '22px',
      letterSpacing: isWeb ? 0.4 : 0,
      marginTop: 0,
      marginBottom: 10,
      color: darkmode ? Colors.WHITE : Colors.BLACK,
    },
    img: {
      maxWidth: isWeb ? maxWidth - 26 : maxWidth - hs(38),
      resizeMode: 'contain',
      alignSelf: 'center',
      borderRadius: 4,
      marginBottom: 5,
      lineHeight: '0.2px',
    },
    blockquote: {
      fontSize: 18,
      fontStyle: 'italic',
      textAlign: 'justify',
      borderLeftWidth: 3,
      borderStyle: 'solid',
      borderLeftColor: 'black',
      margin: '0px 0px 14px 0px',
      padding: 14,
      backgroundColor: 'rgb(249,250,251)',
    },
  };
  const isNumber = (str) => {
    const result = /^\d/.test(str);
    return result;
  };

  return (
    <View>
      <TableOfContents
        scrollRef={innerRef}
        scrollY={scrollY}
        htmlContent={htmlContent}
        handleShowAll={handleShowAll}
      />
      <View
        style={{
          paddingHorizontal: isWeb ? 0 : 17,
        }}>
        {tempContent.length > 0 ? (
          loadedPages.map((pageNum) => (
            <View
              key={pageNum}
              ref={(el) => (pageRefs.current[pageNum - 1] = el)}>
              <MemoizedRenderHtml
                source={{ html: tempContent[pageNum - 1] }}
                contentWidth={screenWidth}
                ignoredDomTags={IGNORED_TAGS}
                tagsStyles={HTML_CONFIG}
                enableExperimentalGhostLinesPrevention
                systemFonts={systemFonts}
                renderers={renderers}
                customHTMLElementModels={customHTMLElementModels}
                defaultTextProps={{ selectable: true }}
              />
              {pageNum <= tempContent.length &&
                !pageHistory.current.includes(pageNum + 1) && (
                  <View
                    style={[
                      styles('popup'),
                      count.includes(pageNum + 1) ? { display: 'none' } : null,
                      pageNum === tempContent.length
                        ? { display: 'none' }
                        : null,
                    ]}>
                    <View style={styles('popupContent')}>
                      <Text>
                        {isNumber(headingsRef.current[pageNum + 1])
                          ? `${headingsRef.current[pageNum + 1]}`
                          : `${pageNum + 1}. ${
                              headingsRef.current[pageNum + 1]
                            }`}
                      </Text>
                      <ActivityIndicator
                        ref={(el) =>
                          (activityIndicatorsRef.current[pageNum - 1] = el)
                        }
                        size="large"
                        color="#000000"
                        style={styles('indicator')}
                      />
                    </View>
                  </View>
                )}
            </View>
          ))
        ) : (
          <MemoizedRenderHtml
            source={{
              html: splitContent
                ? tempContent[0]
                : parsedContent ?? '<html></html>',
            }}
            contentWidth={screenWidth}
            ignoredDomTags={IGNORED_TAGS}
            tagsStyles={HTML_CONFIG}
            enableExperimentalGhostLinesPrevention
            systemFonts={systemFonts}
            renderers={renderers}
            customHTMLElementModels={customHTMLElementModels}
            defaultTextProps={{ selectable: true }}
          />
        )}
      </View>
    </View>
  );
};

const webStyles = {
  contentContainer: {
    paddingHorizontal: isWeb ? 0 : 17,
  },
  articleBody: {
    width: '100%',
  },
  thumbnailImg: {
    resizeMode: 'contain',
    height: 560,
    width: '100%',
    alignSelf: 'center',
  },
  popup: {
    borderColor: '#000',
    borderWidth: 1,
    padding: 10,
    backgroundColor: '#fff',
    borderRadius: 5,
    alignSelf: 'center',
    marginBottom: 20,
  },
  popupContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  indicator: {
    marginLeft: 10,
  },
};

const mobileStyles = {
  articleBody: {
    width: screenWidth,
  },
  thumbnailImg: {
    ...General.image,
    ...Section.focusSectionImage,
  },
};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

InfiniteScroll.propTypes = {
  htmlContent: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  innerRef: PropTypes.any,
  type: PropTypes.string,
  darkmode: PropTypes.bool,
  revisionId: PropTypes.string,
  scrollY: PropTypes.number.isRequired,
};

export default InfiniteScroll;
