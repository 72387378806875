import React, { useState, useEffect } from 'react';
import { View, Text, Pressable } from 'react-native';
import PropTypes from 'prop-types';
import { AntDesign } from '@expo/vector-icons';

import { Colors } from '@web-styles';
import { CreateResponsiveStyle } from '~global-screens';

const TableOfContents = ({
  scrollRef,
  scrollY,
  htmlContent,
  handleShowAll,
}) => {
  const styles = componentStyles();
  const [expanded, setExpanded] = useState(false);
  const [tocItems, setTocItems] = useState([]);
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    if (htmlContent) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');
      const h3Elements = doc.getElementsByTagName('h3');

      const nonEmptyH3Elements = Array.from(h3Elements).filter(
        (h3) => h3.textContent.trim() !== '',
      );

      const headings = nonEmptyH3Elements.length;
      const paragraphs = htmlContent.split(/<p[^>]*>/).filter((p, index) => {
        if (index === 0) {
          return false;
        }
        const cleanP = p.replace(/<\/?[^>]+(>|$)/g, '').trim();
        return (
          cleanP.length > 0 &&
          !cleanP.startsWith('http') &&
          !cleanP.startsWith('Baca Juga:') &&
          !cleanP.startsWith('(Sumber Foto:')
        );
      }).length;

      setShouldShow(headings >= 3 && paragraphs >= 5);

      setTocItems(
        nonEmptyH3Elements.map((h3, index) => ({
          name: h3.textContent.trim(),
          id: `toc-${index}`,
        })),
      );
    }
  }, [htmlContent]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleLinkPress = async (item) => {
    const el = scrollRef.querySelector(
      `[data-testid="h3"][aria-label="${item.name}"]`,
    );

    if (el) {
      // Header is on the current page
      const yOffset = -150;
      const y = el.getBoundingClientRect().top + scrollY + yOffset;
      scrollRef.scrollTo({ y, animated: true });
    } else {
      // Header is not on the current page. Trigger display all pages, and scroll to matching element
      await handleShowAll();

      // Quickly wait for the content to be updated
      setTimeout(() => {
        const updatedEl = scrollRef.querySelector(
          `[data-testid="h3"][aria-label="${item.name}"]`,
        );
        if (updatedEl) {
          const yOffset = -150;
          const y = updatedEl.getBoundingClientRect().top + scrollY + yOffset;
          scrollRef.scrollTo({ y, animated: true });
        }
      }, 100);
    }
  };

  if (!shouldShow) {
    return null;
  }

  return (
    <View style={styles('container')}>
      <Pressable
        onPress={toggleExpanded}
        style={[
          styles('header'),
          // eslint-disable-next-line react-native/no-inline-styles
          {
            borderBottomWidth: expanded ? 1 : 0,
          },
        ]}>
        <Text style={styles('headerText')}>Table of Contents</Text>
        <Text style={styles('expandIcon')}>
          {expanded ? (
            <AntDesign name="up" size={13} color={Colors.BLACK} />
          ) : (
            <AntDesign name="down" size={13} color={Colors.BLACK} />
          )}
        </Text>
      </Pressable>
      {expanded && (
        <View style={styles('content')}>
          {tocItems.map((item) => (
            <Pressable
              key={item.id}
              style={styles('linkItem')}
              onPress={() => handleLinkPress(item)}>
              <Text style={styles('linkText')}>{item.name}</Text>
            </Pressable>
          ))}
        </View>
      )}
    </View>
  );
};

TableOfContents.propTypes = {
  scrollRef: PropTypes.any,
  scrollY: PropTypes.number.isRequired,
  htmlContent: PropTypes.string.isRequired,
  handleShowAll: PropTypes.func.isRequired,
};

const webStyles = {
  container: {
    width: '100%',
    borderWidth: 1,
    borderColor: Colors.BLACK,
    overflow: 'hidden',
    marginBottom: 20,
    backgroundColor: '#F7FB9B',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#F2F962',
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  expandIcon: {
    fontSize: 16,
  },
  content: {
    padding: 10,
  },
  linkItem: {
    paddingVertical: 5,
  },
  linkText: {
    color: Colors.BLACK,
    fontWeight: '500',
  },
};

const mobileStyles = {
  container: {
    width: '100%',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderRadius: 0,
    borderColor: Colors.BLACK,
    overflow: 'hidden',
    marginBottom: 20,
    backgroundColor: '#F7FB9B',
  },
};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

export default TableOfContents;
