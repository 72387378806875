/* eslint-disable react-native/no-inline-styles */
import React, { useMemo } from 'react';
import { View, Image, Pressable, Linking } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { CreateResponsiveStyle, screenWidth, isWeb } from '~global-screens';
import {
  toggleModalStatus,
  selectBannerData,
  setBannerSession,
} from '~store/app/slice';

import BaseModal from './BaseModal';

const BannerModal = ({ visible, routeActive }) => {
  const dispatch = useDispatch();
  const styles = componentStyles();
  const bannerData = useSelector(selectBannerData);

  const popUpBanner = useMemo(() => {
    let res = '';
    const locationHash = {
      HomeScreen: 'homepage',
      ArticleDetailScreen: 'article-pages',
      QuizScreen: 'quiz-pages',
      SearchScreen: 'category-pages',
    };
    if (bannerData) {
      res = bannerData.find(
        (e) =>
          e.type === 'pop-up-banner' &&
          e.location === (locationHash[routeActive] || 'all-pages'),
      );
    }
    return res;
  }, [bannerData, routeActive]);

  if (!popUpBanner) {
    dispatch(toggleModalStatus('banner', false));
    return null;
  }

  const imageSource = isWeb
    ? popUpBanner?.desktop_image
    : popUpBanner?.mobile_image;
  const aspectRatio = isWeb ? 1.75 : 300 / 533;
  const width = isWeb ? 900 / 1.45 : screenWidth * 0.65;

  const toggle = () => {
    dispatch(toggleModalStatus('banner', false));
    if (routeActive !== 'ArticleDetailScreen') {
      dispatch(setBannerSession(routeActive));
    }
  };

  return (
    <BaseModal visible={visible} toggle={toggle}>
      <View>
        <Pressable
          onPress={() => {
            Linking.openURL(popUpBanner.link);
          }}>
          <Pressable onPress={toggle} style={styles('closeBtn')}>
            <AntDesign
              name="closecircle"
              size={isWeb ? 24 : 16}
              color="black"
            />
          </Pressable>
          <Image
            source={imageSource}
            style={{
              aspectRatio,
              width,
              position: 'relative',
            }}
            role="presentation"
            alt={'banner'}
          />
        </Pressable>
      </View>
    </BaseModal>
  );
};
const webStyles = {
  closeBtn: {
    position: 'absolute',
    top: isWeb ? -15 : '-1vh',
    right: isWeb ? -15 : -10,
    zIndex: 999,
    backgroundColor: 'white',
    borderRadius: 50,
    width: isWeb ? 24 : 16,
    height: isWeb ? 24 : 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const mobileStyles = {};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

BannerModal.propTypes = {
  visible: PropTypes.bool,
  routeActive: PropTypes.string,
};
export default BannerModal;
