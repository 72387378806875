import React, { memo } from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import PropTypes from 'prop-types';

import { isWeb } from '~global-screens';
const starGraySource = require('~assets/images/star-grey.svg');
const starFillSource = require('~assets/images/star-fill.svg');

const RatingStar = (props) => {
  const starWidth = props.starWidth;
  const starStyleCustom = props.starStyleCustom ? props.starStyleCustom : {};
  const starStyle = { ...StyleSheet.flatten(styles.star), ...starStyleCustom };
  const maxRating = [1, 2, 3, 4, 5];
  const ratingValue = Number(props.rating) || 0;
  const rating =
    ratingValue * starWidth + ratingValue.toFixed(1) * starStyle.marginRight;

  const ListStar = () => {
    return (
      <View>
        <View style={styles.starWrapper}>
          {maxRating.map((item) => (
            <Image
              source={starGraySource}
              style={{
                ...styles.itemRating,
                width: props.starWidth,
                height: props.starHeight,
              }}
              key={`starGray${item}`}
            />
          ))}
        </View>
        <View
          // width={rating}
          style={[
            styles.starWrapper,
            styles.starWrapperOverlay,
            { width: rating },
          ]}>
          {maxRating.map((item) => (
            <Image
              source={starFillSource}
              style={{
                ...styles.itemRating,
                width: props.starWidth,
                height: props.starHeight,
              }}
              key={`starFill${item}`}
            />
          ))}
        </View>
      </View>
    );
  };

  return (
    <View style={styles.ratingArea}>
      <View style={[styles.starArea, { height: props.starHeight }]}>
        <ListStar />
      </View>
      {Boolean(props.count) && (
        <Text style={[styles.ratingText, props.ratingText]}>
          ({props.count})
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  ratingArea: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 5,
  },
  starWrapper: {
    flexDirection: 'row',
    marginRight: 5,
    zIndex: 1,
    overflow: 'hidden',
  },
  starWrapperOverlay: {
    position: 'absolute',
    left: 0,
    zIndex: 2,
  },
  star: {
    ...(isWeb
      ? {
          marginRight: 4.37,
        }
      : {
          marginRight: 2,
        }),
  },
  // eslint-disable-next-line react-native/no-color-literals
  ratingText: {
    fontSize: 11,
    lineHeight: 12,
    fontFamily: 'Work Sans',
    color: '#8C8582',
  },
  itemRating: {
    ...(isWeb
      ? {
          marginRight: 5,
        }
      : {
          marginRight: 2,
        }),
  },
});

RatingStar.defaultProps = {
  rating: 5,
  starWidth: 16,
  starHeight: 16,
};

RatingStar.propTypes = {
  rating: PropTypes.any,
  starWidth: PropTypes.number.isRequired,
  starHeight: PropTypes.number.isRequired,
  count: PropTypes.number,
  starStyleCustom: PropTypes.any,
  ratingText: PropTypes.any,
  ratingCountText: PropTypes.any,
  type: PropTypes.string,
};

export default memo(RatingStar);
