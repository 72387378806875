import React, { Suspense, useEffect, lazy, useMemo } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getPathFromState, getStateFromPath } from '@react-navigation/native';
import PropTypes from 'prop-types';
import md5 from 'md5';
import axios from 'axios';

import { LoginModal, BannerModal, ShareModal } from '@web-components';
import { revisionRegex } from '../../web/components/htmlRender/Regex.constant';
import {
  selectModalStatus,
  toggleModalStatus,
  selectBannerData,
} from '~store/app/slice';
import { isWeb } from '~global-screens';
import { setAuthenticated, setUserData } from '~store/auth/authSlice';
import { selectSearchFetchStatus } from '~store/search/searchSlice';
import {
  getInfiniteBottomListCount,
  getInfiniteBottomList,
} from '~store/section/sectionThunk';

import { getBanners, getArticleMegaMenu } from '~store/app/thunks';
import { getArticleCategoryIds } from '../../../store/app/thunks';

const Config = require('../../../config/default.env').default;

const HomeScreen = lazy(() => import('@web/screens/HomeScreen'));
const WhatsNewScreen = lazy(() => import('@web/screens/WhatsNewScreen'));
const WhatsGoodScreen = lazy(() => import('@web/screens/WhatsGoodScreen'));
const WhatsFunScreen = lazy(() => import('@web/screens/WhatsFunScreen'));
const BeautyAzScreen = lazy(() => import('@web/screens/BeautyAzScreen'));
const ArticleDetailScreen = lazy(() =>
  import('@web/screens/ArticleDetailScreen'),
);
const SearchScreen = lazy(() => import('@web/screens/SearchScreen'));
const GlossaryScreen = lazy(() => import('@web/screens/GlossaryScreen'));
const QuizScreen = lazy(() => import('@web/screens/QuizScreen'));
const ShortScreen = lazy(() => import('@web/screens/ShortScreen'));
const BeautyShortScreen = lazy(() => import('@web/screens/BeautyShortScreen'));
const LoaderOverlay = lazy(() =>
  import('~global-components/loaderOverlay/LoaderOverlay'),
);
const GlossaryDetailScreen = lazy(() =>
  import('@web/screens/GlossaryDetailScreen'),
);
const NotFoundScreen = lazy(() => import('@web/screens/NotFoundScreen'));
const DetailScreen = lazy(() => import('@web/screens/DetailScreen'));
const AboutUsScreen = lazy(() => import('@web/screens/AboutUsScreen'));
const ContactUsScreen = lazy(() => import('@web/screens/ContactUsScreen'));

const Header = lazy(() => import('@web-components/headers/Header'));

const components = {
  HomeScreen,
  WhatsNewScreen,
  WhatsGoodScreen,
  WhatsFunScreen,
  BeautyAzScreen,
  ArticleDetailScreen,
  SearchScreen,
  GlossaryScreen,
  QuizScreen,
  ShortScreen,
  BeautyShortScreen,
  GlossaryDetailScreen,
  NotFoundScreen,
  DetailScreen,
  AboutUsScreen,
  ContactUsScreen,
};

const getScreenComponent = (name) => components[name];

const Stack = createStackNavigator();

const screensConfig = {
  HomeScreen: '',
  WhatsNewScreen: '/whats-new',
  WhatsGoodScreen: '/whats-good',
  WhatsFunScreen: '/whats-fun',
  BeautyAzScreen: '/beauty-az',
  QuizScreen: '/quiz/:id',
  ArticleDetailScreen: '/article/:slug',
  ShortScreen: '/beauty-short/:slug',
  BeautyShortScreen: '/beauty-short',
  GlossaryScreen: '/glossary',
  GlossaryDetailScreen: '/glossary/:id',
  DetailScreen: '/glossaries/:id',
  PreviewArticleDetailScreen: '/preview/article/:slug',
  PreviewQuizScreen: '/preview/quiz/:id',
  PreviewShortScreen: '/preview/beauty-short/:slug',
  AboutUsScreen: '/about-us',
  ContactUsScreen: '/contact-us',
  SearchScreen: '/*/:subcategory',
  NotFoundScreen: '*',
};

const getCurrentState = (state) => {
  if (state?.routes?.length) {
    const index = state.index || 0;
    if (state.routes[index].state) {
      return getCurrentState(state.routes[index].state);
    } else {
      return state.routes[index];
    }
  }
  return {};
};

const validCategories = new Set([
  'whats-new',
  'whats-good',
  'whats-fun',
  'beauty-az',
  'tag',
  'search',
]);

const checkPage = (name) => {
  const page = name.split('/');
  if (name === '/' || page.length === 0 || !page) {
    return null;
  }
  if (page.length > 2) {
    const slug = page[2];
    if (page[1] === 'preview') {
      let previewPath;

      const rawSlug = page[3];
      const getRevision = rawSlug?.match(revisionRegex);
      let slugPreview = rawSlug?.replace(`?${getRevision?.[0]}`, '');
      const getPage = rawSlug.match(/\page=(all|\d+)/);
      if (getPage?.[0]) {
        slugPreview = slugPreview.replace(`&${getPage?.[0]}`, '');
      }
      const revisionPreview = `${getRevision?.[1]}&${getPage?.[0]}`;

      switch (page[2]) {
        case 'article':
          previewPath = {
            name: 'PreviewArticleDetailScreen',
            params: {
              slug: slugPreview,
              revision: revisionPreview,
              type: 'preview',
            },
          };
          break;
        case 'quiz':
          previewPath = {
            name: 'PreviewQuizScreen',
            params: { id: slugPreview, revision: revisionPreview },
          };
          break;
        case 'beauty-short':
          previewPath = {
            name: 'PreviewShortScreen',
            params: { slug: slugPreview, revision: revisionPreview },
          };
          break;
        default:
          break;
      }

      return previewPath;
    }
    if (/article|page/.test(page[1])) {
      let articleSlug = slug;

      if (page[3] && (page[1] === 'page' || page[3] !== 'amp')) {
        articleSlug = page[3];
      }

      return {
        name: 'ArticleDetailScreen',
        params: { slug: articleSlug, type: '' },
      };
    }
    if (page[1] === 'quiz') {
      return {
        name: 'QuizScreen',
        params: { id: slug },
      };
    }
    if (page[1] === 'glossary') {
      if (slug === 'detail' && page.length > 3) {
        return {
          name: 'DetailScreen',
          params: { id: page[3] },
        };
      } else {
        return {
          name: 'GlossaryDetailScreen',
          params: { id: slug },
        };
      }
    }
    if (page[1] === 'glossaries') {
      return {
        name: 'DetailScreen',
        params: { id: slug },
      };
    }
    if (page[1] === 'beauty-short') {
      if (page[2]) {
        return {
          name: 'ShortScreen',
          params: { slug: slug },
        };
      } else {
        return {
          name: 'BeautyShortScreen',
        };
      }
    }
    if (page[1] === 'about-us') {
      return {
        name: 'AboutUsScreen',
      };
    }
    if (page[1] === 'contact-us') {
      return {
        name: 'ContactUsScreen',
      };
    }
    if (validCategories.has(page[1])) {
      let convertedSlug = slug;
      if (slug === 'quizzes-') {
        convertedSlug = 'quizzes-poll';
      }
      return {
        name: 'SearchScreen',
        params: { keyword: convertedSlug, type: '', route: page[1] },
      };
    }
  }

  const allowRedirectToSearchScreen = Object.values(screensConfig)
    .map((url) => url.split('/')[1])
    .every((path) => path !== page[1]);

  if (!/^search([?]|$)/.test(page[1]) && allowRedirectToSearchScreen) {
    return {
      name: 'SearchScreen',
      params: { keyword: page[1], route: 'search' },
    };
  }
};

export const config = {
  prefixes: [
    'http://localhost:19006',
    'https://bj.id',
    'https://uat-bj-apps.sociolabs.io',
    'https://beautyjournal.sociolla.com',
    'https://beautyjournal.id',
  ],
  config: {
    screens: screensConfig,
  },
  getStateFromPath: (path, options) => {
    const page = checkPage(path);
    let newPath = path;
    if (page) {
      const { name, params } = page;
      switch (name) {
        case 'ArticleDetailScreen':
          newPath = `/article/${params.slug}`;
          break;
        case 'SearchScreen':
          newPath = `/${params.route}/${params.keyword}`;
          break;
        case 'ShortScreen':
          newPath = `/beauty-short/${params.slug}`;
          break;
        case 'QuizScreen':
          newPath = `/quiz/${params.id}`;
          break;
        case 'GlossaryDetailScreen':
          newPath = `/glossary/${params.id}`;
          break;
        case 'DetailScreen':
          newPath = `/glossaries/${params.id}`;
          break;
        case 'PreviewArticleDetailScreen':
          newPath = `/preview/article/${params.slug}?revision=${params.revision}`;
          break;
        case 'PreviewQuizScreen':
          newPath = `/preview/quiz/${params.id}?revision=${params.revision}`;
          break;
        case 'PreviewShortScreen':
          newPath = `/preview/beauty-short/${params.slug}?revision=${params.revision}`;
          break;
        case 'AboutUsScreen':
          newPath = '/about-us';
          break;
        case 'ContactUsScreen':
          newPath = '/contact-us';
          break;
        default:
          break;
      }
    }
    return getStateFromPath(newPath, options);
  },
  getPathFromState: (state, config) => {
    const path = getPathFromState(state, config);
    const current = getCurrentState(state);
    let newPath = path;
    switch (current.name) {
      case 'WhatsNewScreen':
        newPath = '/whats-new';
        break;
      case 'WhatsGoodScreen':
        newPath = '/whats-good';
        break;
      case 'WhatsFunScreen':
        newPath = '/whats-fun';
        break;
      case 'BeautyAzScreen':
        newPath = '/beauty-az';
        break;
      case 'QuizScreen':
        newPath = `/quiz/${current.params.id}`;
        break;
      case 'ArticleDetailScreen':
        newPath = `/article/${current.params.slug}`;
        break;
      case 'SearchScreen':
        newPath = `/${current.params.route}/${current.params.keyword
          .split(' ')
          .join('-')}`;
        break;
      case 'ShortScreen':
        newPath = `/beauty-short/${current.params.slug}`;
        break;
      case 'BeautyShortScreen':
        newPath = '/beauty-short';
        break;
      case 'GlossaryScreen':
        newPath = '/glossary';
        break;
      case 'GlossaryDetailScreen':
        newPath = `/glossary/${current.params.id}`;
        break;
      case 'DetailScreen':
        newPath = `/glossaries/${current.params.id}`;
        break;
      case 'PreviewArticleDetailScreen':
        newPath = `/preview/article/${current.params.slug}?revision=${current.params.revision}`;
        break;
      case 'PreviewQuizScreen':
        newPath = `/preview/quiz/${current.params.id}?revision=${current.params.revision}`;
        break;
      case 'PreviewShortScreen':
        newPath = `/preview/beauty-short/${current.params.slug}?revision=${current.params.revision}`;
        break;
      case 'AboutUsScreen':
        newPath = '/about-us';
        break;
      case 'ContactUsScreen':
        newPath = '/contact-us';
        break;
      default:
        newPath = '/';
        break;
    }

    return newPath;
  },
};

const RootNavigator = (props) => {
  const { routeActive } = props;
  const dispatch = useDispatch();
  const isLoginModalOpen = useSelector(selectModalStatus('loginModal'));
  const isBannerModalOpen = useSelector(selectModalStatus('banner'));
  const isShareModalOpen = useSelector(selectModalStatus('shareModal'));
  const bannerData = useSelector(selectBannerData);

  const searchDataFetch = useSelector(
    selectSearchFetchStatus('getSearchDataFetch'),
  );

  const popUpBanner = useMemo(() => {
    let res = '';
    const locationHash = {
      HomeScreen: 'homepage',
      ArticleDetailScreen: 'article-pages',
      QuizScreen: 'quiz-pages',
      SearchScreen: 'category-pages',
    };
    if (bannerData) {
      res = bannerData.find(
        (e) =>
          e.type === 'pop-up-banner' &&
          e.location === (locationHash[routeActive] || 'all-pages'),
      );
    }
    return res;
  }, [bannerData, routeActive]);

  useEffect(() => {
    if (routeActive && popUpBanner) {
      const { location } = popUpBanner;
      let showBanner = false;

      switch (true) {
        case routeActive === 'HomeScreen' && location === 'homepage':
        case routeActive === 'ArticleDetailScreen' &&
          location === 'article-pages':
        case routeActive === 'QuizScreen' && location === 'quiz-pages':
        case routeActive === 'SearchScreen' && location === 'category-pages':
        case location === 'all-pages':
          showBanner = true;
          break;
        default:
          break;
      }
      setTimeout(() => {
        dispatch(toggleModalStatus('banner', showBanner));
      }, 500);
    }

    return () => {
      clearTimeout();
    };
  }, [routeActive, popUpBanner]);

  const fetchToken = async () => {
    const token = await AsyncStorage.getItem('SOCIOLLA_UID');
    const user = await AsyncStorage.getItem('USER_DATA');
    if (token && user) {
      dispatch(setAuthenticated(true));
      dispatch(setUserData(user));
    }
  };

  const gtmInitialize = async () => {
    try {
      const res = await axios.get(`${Config.MS_SOCO_PUBLIC_API_URL}/user/me`);

      if (res.data.data !== null) {
        const dataUser = res.data.data;
        const {
          my_sql_id: userID = '',
          _id: userIDMongo = '',
          email: userEmail = '',
          user_name: userName = '',
          first_name: userFirstname = '',
          last_name: userLastname = '',
          date_of_birth: userBirth = '',
          gender: userGender = '',
          phone_no: userPhone = '',
        } = dataUser;

        const hashedEmail = md5(userEmail);

        const data = {
          event: 'loggedIn',
          user_id: userID,
          user_id_mongo: userIDMongo,
          user_data: {
            userID,
            userIDMongo,
            userEmail,
            hashedEmail,
            userName,
            userFirstname,
            userLastname,
            userBirth,
            userGender,
            userPhone,
          },
        };
        window.dataLayer.push(data);
      }
    } catch (err) {
      console.log(err);
    }

    const gtm_script_check = document.getElementById('gtm-script');
    if (!gtm_script_check) {
      const gtm_script = document.createElement('script');
      gtm_script.id = 'gtm-script';
      gtm_script.defer = true;
      gtm_script.type = 'text/javascript';
      gtm_script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PN6HLN');`;
      document.body.appendChild(gtm_script);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      await fetchToken();
      await gtmInitialize();
      dispatch(getBanners());
      dispatch(getInfiniteBottomListCount());
      dispatch(getInfiniteBottomList());
      if (isWeb) {
        dispatch(async (dispatch) => {
          try {
            const categoriesAction = await dispatch(getArticleCategoryIds());
            const categories = categoriesAction.payload.data.data;
            if (categories && categories.length) {
              await Promise.all(
                categories.map((category) => {
                  dispatch(
                    getArticleMegaMenu({
                      categoryId: category._id,
                      slug: category.slug,
                    }),
                  );
                }),
              );
            }
          } catch (error) {
            console.error('Error fetching categories or mega menu:', error);
          }
        });
      }
    };

    initialize();
  }, []);

  return (
    <Suspense fallback={<LoaderOverlay show={true} full />}>
      <Stack.Navigator
        screenOptions={{
          header: (props) => {
            if (routeActive === 'NotFoundScreen') {
              return null;
            }
            return <Header {...props} />;
          },
          cardStyle: {
            flex: 1,
            overflow: 'hidden',
          },
        }}>
        <Stack.Screen
          name="HomeScreen"
          component={getScreenComponent('HomeScreen')}
          headerShown="false"
          options={{
            title:
              'Beauty Journal - Highlight Beauty hingga Lifestyle | Trend, Tips & Review',
          }}
        />
        <Stack.Screen
          name="WhatsNewScreen"
          component={getScreenComponent('WhatsNewScreen')}
          options={{
            title: "What's New - Beauty Journal",
          }}
        />
        <Stack.Screen
          name="WhatsGoodScreen"
          component={getScreenComponent('WhatsGoodScreen')}
          options={{
            title: "What's Good - Beauty Journal",
          }}
        />
        <Stack.Screen
          name="WhatsFunScreen"
          component={getScreenComponent('WhatsFunScreen')}
          options={{
            title: "What's Fun - Beauty Journal",
          }}
        />
        <Stack.Screen
          name="BeautyAzScreen"
          component={getScreenComponent('BeautyAzScreen')}
          options={{
            title: 'Beauty A-Z - Beauty Journal',
          }}
        />
        <Stack.Screen
          name="SearchScreen"
          component={getScreenComponent('SearchScreen')}
          options={{
            title: 'Search - Beauty Journal',
          }}
        />
        <Stack.Screen
          name="ArticleDetailScreen"
          component={getScreenComponent('ArticleDetailScreen')}
          options={{
            title: 'Article - Beauty Journal',
          }}
        />
        <Stack.Screen
          name="GlossaryScreen"
          component={GlossaryScreen}
          options={{
            title: 'Glossary - Beauty Journal',
          }}
        />
        <Stack.Screen
          name="QuizScreen"
          component={getScreenComponent('QuizScreen')}
          options={{
            title: 'Quiz - Beauty Journal',
          }}
        />
        <Stack.Screen
          name="ShortScreen"
          component={ShortScreen}
          options={{
            title: 'Beauty Short - Beauty Journal',
          }}
        />
        <Stack.Screen
          name="BeautyShortScreen"
          component={getScreenComponent('BeautyShortScreen')}
          options={{
            title: 'Beauty Short - Beauty Journal',
          }}
        />
        <Stack.Screen
          name="GlossaryDetailScreen"
          component={getScreenComponent('GlossaryDetailScreen')}
          options={{
            title: 'A Glossary - Beauty Journal',
          }}
        />
        <Stack.Screen
          name="NotFoundScreen"
          component={getScreenComponent('NotFoundScreen')}
          options={{
            title: '404',
          }}
        />
        <Stack.Screen
          name="DetailScreen"
          component={getScreenComponent('DetailScreen')}
          options={{
            title: 'Beauty Short - Glossary',
          }}
        />
        <Stack.Screen
          name="PreviewArticleDetailScreen"
          component={getScreenComponent('ArticleDetailScreen')}
          options={{
            title: 'Article - Beauty Journal',
          }}
        />
        <Stack.Screen
          name="PreviewQuizScreen"
          component={getScreenComponent('QuizScreen')}
          options={{
            title: 'Quiz - Beauty Journal',
          }}
        />
        <Stack.Screen name="PreviewShortScreen" component={ShortScreen} />
        <Stack.Screen
          name="AboutUsScreen"
          component={getScreenComponent('AboutUsScreen')}
          options={{
            title:
              'Beauty Journal - Highlight Beauty hingga Lifestyle | Trend, Tips & Review Temukan tips, guide, inspirasi dalam bentuk artikel dan video, serta ensiklopedia terlengkap dalam Beauty Journal!',
          }}
        />
        <Stack.Screen
          name="ContactUsScreen"
          component={getScreenComponent('ContactUsScreen')}
          options={{
            title:
              'Beauty Journal - Highlight Beauty hingga Lifestyle | Trend, Tips & Review Temukan tips, guide, inspirasi dalam bentuk artikel dan video, serta ensiklopedia terlengkap dalam Beauty Journal!',
          }}
        />
      </Stack.Navigator>
      <LoginModal visible={isLoginModalOpen} />
      <BannerModal visible={isBannerModalOpen} {...props} />
      <ShareModal visible={isShareModalOpen} />
      <LoaderOverlay show={searchDataFetch} full />
    </Suspense>
  );
};
RootNavigator.propTypes = {
  routeActive: PropTypes.string,
};

export default RootNavigator;
