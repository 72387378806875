import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  API_TRENDING_TAG_BJ,
  API_SEARCH_BJ,
  API_POSTS_BJ,
} from '~global-api/bj.api';
import { API_PRODUCT_SOCO } from '~global-api/soco.api';

export const getTrendingTag = createAsyncThunk(
  'search/getTrendingTag',
  async (keyword) => {
    const response = await API_TRENDING_TAG_BJ({
      params: {
        filter: { $or: [{ name: { $regex: keyword, $options: 'i' } }] },
        sort: '-created_at',
        limit: 5,
      },
    });
    return response.data;
  },
);

export const getProductFeatured = createAsyncThunk(
  'search/getProductFeatured',
  async () => {
    const response = await API_PRODUCT_SOCO(
      {
        params: {
          filter: {
            is_reviewed: true,
          },
          section: 'haveYouTriedProducts',
          fields:
            'active brand default_category discount_type id images la_ps_product_id_product max_price min_price name has_bookmarked price price_after_discount review_stats total_reviews parent_category is_reviewed',
          skip: 0,
          limit: 5,
        },
      },
      '/featured/have-you-tried',
    );
    return response.data;
  },
);

export const getSearchTotalCount = createAsyncThunk(
  'search/getSearchTotalCount',
  async (payload) => {
    const response = await API_SEARCH_BJ(
      {
        params: {
          filter: {
            keyword: payload.keyword,
            ...(payload.type ? { post_type: payload.type } : {}),
          },
        },
      },
      '/count',
    );
    return {
      count: response.data.data,
      ...(payload.type ? { type: payload.type } : {}),
    };
  },
);

export const getSearchData = createAsyncThunk(
  'search/getSearchData',
  async (payload) => {
    const response = await API_SEARCH_BJ({
      params: {
        filter: {
          keyword: payload.keyword,
          ...(payload.type ? { post_type: payload.type } : {}),
        },
        skip: payload.skipValue ?? 0,
        limit: 12,
        ...(payload.fields && { fields: payload.fields }),
      },
    });

    return {
      data: response.data,
      ...(payload.type ? { type: payload.type } : {}),
    };
  },
);

export const getTagTotalCount = createAsyncThunk(
  'search/getTagTotalCount',
  async (payload) => {
    const post_type = payload.type
      ? payload.type
      : {
          $in: ['bjtv', 'article', 'bjglossary', 'pid_quiz', 'polling'],
        };
    const response = await API_POSTS_BJ({
      method: 'get',
      url: '/count',
      payload: {
        params: {
          filter: {
            post_type,
            status: 'publish',
            'owner.user_type': { $nin: ['community'] },
            'tags.slug': payload.keyword,
          },
        },
      },
    });

    return {
      count: response.data.count,
      ...(payload.type ? { type: payload.type } : {}),
    };
  },
);

export const getTagResultData = createAsyncThunk(
  'search/getTagResultData',
  async (payload) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      payload: {
        params: {
          filter: {
            ...(payload.type ? { post_type: payload.type } : {}),
            'owner.user_type': { $nin: ['community'] },
            'tags.slug': payload.keyword,
          },
          sort: '-published_at',
          skip: payload.skipValue ?? 0,
          limit: 12,
        },
      },
    });
    return {
      data: response.data,
      ...(payload.type ? { type: payload.type } : {}),
    };
  },
);

export const getSubCatCount = createAsyncThunk(
  'search/getSubCatCount',
  async (payload) => {
    const post_type = payload.type
      ? payload.type
      : {
          $in: ['bjtv', 'article', 'bjglossary', 'pid_quiz', 'polling'],
        };
    const response = await API_POSTS_BJ({
      method: 'get',
      url: '/count',
      payload: {
        params: {
          filter: {
            post_type,
            'subcategory.slug': payload.keyword,
          },
        },
      },
    });

    return {
      count: response.data.count,
      ...(payload.type ? { type: payload.type } : {}),
    };
  },
);

export const getSubCatResultData = createAsyncThunk(
  'search/getSubCatResultData',
  async (payload) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      payload: {
        params: {
          filter: {
            ...(payload.type ? { post_type: payload.type } : {}),
            'subcategory.slug': payload.keyword,
          },
          fields:
            '_id title slug owner summary category attachments post_type published_at created_at subcategory total_views',
          sort: '-published_at',
          skip: payload.skipValue ?? 0,
          limit: 12,
        },
      },
    });
    return {
      data: response.data,
      ...(payload.type ? { type: payload.type } : {}),
    };
  },
);

export const getCatCount = createAsyncThunk(
  'search/getCatCount',
  async (payload) => {
    const post_type = payload.type
      ? payload.type
      : {
          $in: ['bjtv', 'article', 'bjglossary', 'pid_quiz', 'polling'],
        };
    const response = await API_POSTS_BJ({
      method: 'get',
      url: '/count',
      payload: {
        params: {
          filter: {
            post_type,
            'subcategory.slug': payload.keyword,
          },
        },
      },
    });

    return {
      count: response.data.count,
      ...(payload.type ? { type: payload.type } : {}),
    };
  },
);

export const getCatResultData = createAsyncThunk(
  'search/getCatResultData',
  async (payload) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      payload: {
        params: {
          filter: {
            ...(payload.type ? { post_type: payload.type } : {}),
            'category.slug': payload.keyword,
          },
          fields:
            '_id title slug owner summary category attachments post_type published_at created_at subcategory total_views',
          sort: '-published_at',
          skip: payload.skipValue ?? 0,
          limit: 12,
        },
      },
    });
    return {
      data: response.data,
      ...(payload.type ? { type: payload.type } : {}),
    };
  },
);

export const getQuizCount = createAsyncThunk(
  'search/getQuizCount',
  async (payload) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      url: '/count',
      payload: {
        params: {
          filter: {
            post_type: { $in: ['pid_quiz', 'polling'] },
          },
        },
      },
    });

    return {
      count: response.data.count,
      ...(payload.type ? { type: payload.type } : {}),
    };
  },
);

export const getQuizResultData = createAsyncThunk(
  'search/getQuizResultData',
  async (payload) => {
    const response = await API_POSTS_BJ({
      method: 'get',
      payload: {
        params: {
          filter: {
            post_type: { $in: ['pid_quiz', 'polling'] },
          },
          fields:
            '_id title slug owner summary category attachments post_type published_at created_at subcategory total_views',
          sort: '-published_at',
          skip: payload.skipValue ?? 0,
          limit: 10,
        },
      },
    });
    return {
      data: response.data,
      ...(payload.type ? { type: payload.type } : {}),
    };
  },
);

export const getTrendingTopics = createAsyncThunk(
  'search/getTrendingTopics',
  async () => {
    const response = await API_TRENDING_TAG_BJ({
      params: {
        filter: { 'highlight.active': true, location: 'now-trending' },
        sort: 'highlight.position',
        limit: 5,
      },
    });
    return response.data;
  },
);
