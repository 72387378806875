import React, { useCallback, useMemo, useRef, useState } from 'react';
import { View, FlatList } from 'react-native';
import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html';
import PropTypes from 'prop-types';

import { CreateResponsiveStyle, isWeb, screenWidth } from '~global-screens';
import { CdnImage } from '~global-components';
import { Colors } from '@web-styles';
import { Spacer, PaginationDot } from '@web-components';

const customHTMLElementModels = {
  'image-slider': HTMLElementModel.fromCustomModel({
    tagName: 'image-slider',
    contentModel: HTMLContentModel.block,
  }),
};

const parseImageData = (i, imgArray) => {
  const match = imgArray.match(/img_id="([^"]+)"/);
  if (match && match[1]) {
    const urls = match[1].split(',').map((url) => url.trim());
    if (urls.length) {
      return `<image-slider id=[${urls.join(',')}] />`;
    }
  }
  return '';
};

const parseHtmlData = (listData, htmlContent) => {
  let parsedContent = htmlContent;
  for (let i = 0; i < listData.length; i++) {
    const escapedShortcode = listData[i].replace(
      /[-/\\^$*+?.()|[\]{}]/g,
      '\\$&',
    );
    parsedContent = parsedContent.replace(
      new RegExp(escapedShortcode, 'g'),
      parseImageData(i, listData[i]),
    );
  }
  return parsedContent;
};

const ImageSlider = ({ tnode }) => {
  const styles = componentStyles();
  const listRef = useRef(FlatList);
  const imageData = useMemo(() => {
    if (tnode.id) {
      return tnode.id.slice(1, -1).split(',');
    }
  }, [tnode.id]);
  const [activeIndex, setActiveIndex] = useState(1);
  const [width, setWidth] = useState(0);

  const _renderItem = useCallback(({ item }) => {
    return (
      <CdnImage
        imageSize={isWeb ? 500 : 300}
        source={{ uri: item }}
        style={styles('slideImg')}
        resizeMode="contain"
      />
    );
  }, []);

  const handleChangeIndex = (index) => {
    setActiveIndex(index);
    listRef.current.scrollToIndex({
      animated: true,
      index: index - 1,
    });
  };

  const onViewableItemsChanged = useRef(({ viewableItems }) => {
    setActiveIndex(viewableItems?.[0]?.index + 1);
  }).current;

  return (
    <View
      style={styles('container')}
      onLayout={(e) => {
        setWidth(e.nativeEvent.layout.width);
      }}>
      {isWeb && <Spacer width={20} />}
      <View>
        <FlatList
          ref={listRef}
          data={imageData}
          horizontal
          renderItem={_renderItem}
          keyExtractor={(item) => item}
          pagingEnabled
          snapToAlignment="center"
          decelerationRate="fast"
          onViewableItemsChanged={onViewableItemsChanged}
          style={styles('contentContainerStyle')}
          showsHorizontalScrollIndicator={false}
          getItemLayout={(data, index) => ({
            length: width,
            offset: width * index,
            index,
          })}
        />
      </View>
      <PaginationDot
        data={imageData}
        activeIndex={activeIndex}
        activeColor={Colors.CARNATION_PINK}
        inActiveColor={Colors.QUICK_SILVER}
        handleChangeIndex={handleChangeIndex}
      />
      {isWeb && <Spacer width={20} />}
    </View>
  );
};

const webStyles = {
  container: {
    alignSelf: 'center',
    alignItems: 'center',
  },
  slideImg: {
    width: 500,
    height: 500,
    // maxHeight: 500,
    resizeMode: 'contain',
  },
  contentContainerStyle: {
    width: 500,
  },
};

const mobileStyles = {
  slideImg: {
    width: screenWidth - 38,
  },
  contentContainerStyle: {
    width: screenWidth - 38,
  },
};

const componentStyles = CreateResponsiveStyle(webStyles, mobileStyles);

const renderers = {
  'image-slider': ImageSlider,
};

ImageSlider.propTypes = {
  tnode: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default { renderers, customHTMLElementModels, parseHtmlData };
